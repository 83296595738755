require('./bootstrap');
window.IdleTracker = require('idle-tracker').default;


window.addEventListener('app:scroll-to', (ev) => {
    ev.stopPropagation();

    const selector = ev?.detail?.query;
    console.log(selector)

    if (!selector) {
        return;
    }

    const el = window.document.querySelector(selector);

    if (!el) {
        return;
    }

    try {
        el.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });
    } catch {}

}, false);
